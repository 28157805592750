.product-details-container{
    border: 1px solid lightgray;
    width: 95%;
    margin-top: 5px;
    border-radius: 10px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
}

.blog-modal-content img{
    width: 500px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.contact-form input{
  padding: 8px;
}

.contact-form select{
  padding: 8px;
}

.rental-form-image-container{
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.parent{
	display: flex;
	justify-content: center;
	align-items: center;
    height: 100vh;
	background: url("https://img.freepik.com/free-vector/modern-abstract-blue-green-background-with-elegant-elements-vector-illustration_361591-3748.jpg?t=st=1715066811~exp=1715070411~hmac=420b46432015645e268be65d6188b8f1c2c29c25be7c4ccbdcaf3380bf1cf6bc&w=1380") no-repeat center/ cover;

}
.content-container{
	width: 350px;
	height: 350px;
	background: url("https://doc-08-2c-docs.googleusercontent.com/docs/securesc/68c90smiglihng9534mvqmq1946dmis5/fo0picsp1nhiucmc0l25s29respgpr4j/1631524275000/03522360960922298374/03522360960922298374/1Sx0jhdpEpnNIydS4rnN4kHSJtU1EyWka?e=view&authuser=0&nonce=gcrocepgbb17m&user=03522360960922298374&hash=tfhgbs86ka6divo3llbvp93mg4csvb38") no-repeat center/ cover;
	border-radius: 10px;
	box-shadow: 5px 0px 50px #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}
